import { graphql, Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from '~/components/Layout';
import StoryPreview from '~/components/molecules/StoryPreview'
import CustomSEO from '~/components/seo/CustomSEO';

export default function Category({ data, pageContext }) {
  const { previousPagePath, nextPagePath } = pageContext;
  const { wpCategory, allWpPost } = data;

  // Function to append a trailing slash to a path if it's missing
  const addTrailingSlash = (path) => {
    return path.endsWith('/') ? path : `${path}/`;
  };

  return (
    <>
      <Helmet>
        {previousPagePath && (
          <link rel="prev" href={addTrailingSlash(previousPagePath)} />
        )}
        {nextPagePath && (
          <link rel="next" href={addTrailingSlash(nextPagePath)} />
        )}
      </Helmet>

      <Layout showResourcesNav headerBgWhite>
        <Seo post={wpCategory} />
        <CustomSEO seo={wpCategory?.seo} />
        <div className="pt-48 container">
          <h1 className="text-h1">{wpCategory.name}</h1>
        </div>
        <div className="py-20 md:py-32 md:pt-12 container">
          <div className="grid grid-cols-3 gap-8 w-full">
            {allWpPost.nodes.map((item, i) => (
              <StoryPreview key={item?.id + i} {...item} className="w-full" />
            ))}
          </div>
          <div className="mt-12 flex space-x-5">
            {previousPagePath && (
              <Link to={addTrailingSlash(previousPagePath)} className="btn">
                Previous Page
              </Link>
            )}
            {nextPagePath && (
              <Link to={addTrailingSlash(nextPagePath)} className="btn">
                Next Page
              </Link>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}


export const pageQuery = graphql`
  query BlogCategoryQuery($id: String!, $skip: Int!, $limit: Int!) {
    allWpPost(filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }, skip: $skip, limit: $limit) {
      nodes {
        ...PostPreview
      }
    }

    wpCategory(id: { eq: $id }) {
      id
      slug
      name
      seo {
        title
        metaDesc
      }
    }
  }
`;
